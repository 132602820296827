<template>
  <ion-page>
    <ion-content style="--background: var(--ion-color-light)">
    <ion-header>
      <ion-toolbar>
        <ion-title>Notifications</ion-title>
      </ion-toolbar>
    </ion-header>
      <ion-card class="main-card">
        <ion-card-header>
          <ion-card-title>Notifications</ion-card-title>
          <p>View your notifications.</p>
        </ion-card-header>

        <div class="actions">
          <input type="checkbox" name="select" /> Select All

          <img
            class="delete"
            src="/assets/icon/recycle-bin-2.svg"
            height="15"

            @click.prevent="dismissAll()"
          />
        </div>

        <ion-card-content v-if="notifications">
          <ion-card
            v-for="notification in notifications"
            :key="notification"
            class="notification-cards"
          >
            <ion-card-header>
              <ion-card-title style="color: black">{{
                notification.title
              }}</ion-card-title>
              <ion-card-content>
                {{ notification.description }}
                <br />
                <a v-if="notification.link == '#'"
                  class="notifications-links"
                  href="{{notification.link}}"
                  @click.prevent="cancelConsult()"
                  >{{ notification.link_title }}</a
                >
                <router-link v-else
                  class="notifications-links"
                  :to="{ path: notification.link }"
                  >{{ notification.link_title }}</router-link
                >
                <a
                  class="notifications-links"
                  href="#"
                  @click.prevent="dismiss(notification.id)"
                  >DISMISS</a
                >
              </ion-card-content>
            </ion-card-header>
          </ion-card>
        </ion-card-content>
        <ion-card-content v-else>
          <p>No new notifications</p>
        </ion-card-content>
      </ion-card>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonContent
} from "@ionic/vue";

export default {
  name: "Notifications",
  components: {
    IonCardTitle,
    IonCardHeader,
    IonCardContent,
    IonCard,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonPage,
    IonContent
  },
  data() {
    return {
      payload: {
        id: null,
        selected: null,
        user_id: null,
      },
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    notifications() {
      return this.$store.state.notifications.notifications;
    },
  },
  ionViewDidEnter() {
    this.payload.user_id = this.user.id;
    this.$store.dispatch("Notifications", this.user.id).then();
  },
  methods: {
    async dismiss(id) {
      this.payload.id = id;
      await this.$store.dispatch("DismissNotifications", this.payload).then(
        res => {
            console.log(res);
            this.$store.dispatch("Notifications", this.user.id).then();
        }
        ).catch(
          error => {
          console.log(error.error);
          }
      );
    },
    async dismissAll() {
      this.payload.id = null;
      // check that check box is selected
      this.payload.selected = 'all';
      await this.$store.dispatch("DismissNotifications", this.payload).then(
        res => {
            console.log(res);
            this.$store.dispatch("Notifications", this.user.id).then();
        }
        ).catch(
          error => {
          console.log(error.error);
          }
      );
    },
    async cancelConsult() {
      await this.$store.dispatch("CancelConsult", this.user.id).then(
        res => {
            console.log(res);
            this.$store.dispatch("Notifications", this.user.id).then();
        }
        ).catch(
          error => {
          console.log(error.error);
          }
      );
    },
  },
};
</script>

<style scoped>
ion-card-title {
  color: var(--ion-color-primary);
}

.main-card {
  margin-top: 12px;
}

p {
  font-size: 16px;
  line-height: 24px;
}

.actions {
  text-align: left;
  padding-left: 29px;
  padding-right: 28px;
  font-size: 10px;
  line-height: 15px;
}

.delete {
  filter: invert(13%) sepia(46%) saturate(7443%) hue-rotate(355deg)
    brightness(92%) contrast(115%);
  float: right;
}
</style>